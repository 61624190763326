import React from 'react'
import Aboutpage from '../Components/Aboutpage'
import Help from '../Components/Help'

const About = () => {
  return (
    <div>
        <Help/>
        <Aboutpage/>
    </div>
  )
}

export default About