import React from 'react'

const Thanks = () => {
  return (
    <div className='thanks'>
            <img  src='./images/success.png' alt='success image'></img>

        <h3>Your form has been submitted Successfully</h3>
        <p>Expect a feedback from us soon</p>

        <button><a href='https://buy.stripe.com/test_fZe3cQ8BP8l5aFW144'>Proceed to payment</a></button>


    </div>
  )
}

export default Thanks