import React from 'react'
import Contactform from '../Components/Contactform'

const Contact = () => {
  return (
    <div>
        <Contactform/>
    </div>
  )
}

export default Contact