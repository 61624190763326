import React from 'react'
import Bookingform from '../Components/Bookingform'

const Appointment = () => {
  return (
    <div>
        <Bookingform/>
    </div>
  )
}

export default Appointment